<template src='./RecommendedCoursesInstitutions.html'></template>

<script>
export default {
  name: "recommended-courses-institutions",
  props: {
    imageInstitution: { type: String },
    title: { type: String },
    subtitle: { type: [String, Object] },
    body: { type: String, required: false },
    typeCard: { type: Object },
    buttonText: { type: String },
    url: { type: String, default: "" },
    showImage: { type: Boolean, default: true },
    isInstitution: { type: Boolean, default: true },
    imageDescription: { type: String, default: "Imagen del curso recomendado" },
  },
  methods: {
    pushButton() {
      this.$emit("horizontalCardClickButton");
      if (this.isInstitution) {
        this.openCourseUrl(this.url);
      }
    },
    openCourseUrl(url) {
      if (url.length) {
        window.open(url, "_blank");
        this.$router.go(0);
      }
    },
  },
};
</script>

<style lang="scss" scoped src="./RecommendedCoursesInstitutions.scss"></style>