<template src="./Academic.html"></template>

<script>
import { mapGetters } from "vuex";
import { SwiperSlide } from "vue-awesome-swiper";
import coursesService from "@/services/Courses";
import ArrobaMedellinHorizontalCard from "@/components/ArrobaMedellinHorizontalCard/ArrobaMedellinHorizontalCard";
import ArrobaMedellinHorizontalCardAcademic from "@/components/ArrobaMedellinHorizontalCardAcademic/ArrobaMedellinHorizontalCardAcademic";
import ArrobaMedellinVerticalCard from "@/components/ArrobaMedellinVerticalCard/ArrobaMedellinVerticalCard";
import ArrobaMedellinVerticalCardAcademic from "@/components/ArrobaMedellinVerticalCardAcademic/ArrobaMedellinVerticalCardAcademic";
import ArrobaMedellinSeeker from "@/components/ArrobaMedellinSeeker/ArrobaMedellinSeeker";
import ArrobaMedellinArrobitaNoData from "@/components/ArrobaMedellinArrobitaNoData/ArrobaMedellinArrobitaNoData";
import DinamicListFilter from "./DinamicListFilter/DinamicListFilter";
import tutorial from "@/mixins/tutorial";
import swiperSmallOption from "@/utils/swiper-small-option";
import RecommendedCoursesInstitutions from "../../components/RecommendedCoursesInstitutions/RecommendedCoursesInstitutions";
import { roleName } from "@/utils/constants";
import CourseInfoModal from "@/components/CourseInfoModal/CourseInfoModal";

export default {
  name: "academic",
  mixins: [tutorial],
  components: {
    CourseInfoModal,
    SwiperSlide,
    ArrobaMedellinHorizontalCardAcademic,
    ArrobaMedellinHorizontalCard,
    ArrobaMedellinVerticalCard,
    ArrobaMedellinVerticalCardAcademic,
    ArrobaMedellinSeeker,
    DinamicListFilter,
    ArrobaMedellinArrobitaNoData,
    RecommendedCoursesInstitutions,
  },
  data() {
    return {
      showCourseDetail: false,
      typeCard: { button: "button--purple", progress: "is-warning" },
      typeCardInterestedCourses: { size: "small" },
      swiperSmallOption: swiperSmallOption,
      roleName,
      courses: [],
      coursesInProgress: [],
      lastActivities: [],
      nameProgram: "",
      recommendedCourses: [],
      coursesInstitutions: [],
      filteredCourses: [],
      institutionId: "12",
      programId: "77",
      institutions: [],
      category: "Educación continua",
      userInfo: null,
      errorMessage: false,
      noCourses: false,
      itemsToShow: Number(4),
      isCardModalInstitution: false,
      institution: {},
      coursesRecommendationsMedellin: [],
      coursesRecommendationsInstitution: [],
      listLastCourses: [],
      courseIdModal: null,
      modalEditable: false,
      courseImage: "",
    };
  },
  beforeMount() {
    this.role = this.user.role.name;
  },
  mounted() {
    this.getAcademicData();
    this.reportWindowSize();
    this.viewTutorial();
  },
  methods: {
    async getAllCoursesRecommendations() {
      try {
        this.coursesRecommendationsMedellin =
          await coursesService.getAllCoursesRecommendations("@medellin");
        this.coursesRecommendationsInstitution =
          await coursesService.getAllCoursesRecommendations("Universidad");
      } catch (e) {
        console.log("e", e);
      }
    },
    searchCourseOrCategory(searchWord) {
      coursesService
        .getByCourseOrCategory(searchWord)
        .then((response) => {
          if (response.length < 1) {
            this.errorMessage = true;
          } else {
            this.filteredCourses = response;
            this.category = `con la palabra ${searchWord}`;
            this.errorMessage = false;
            this.nameProgram = null;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getCoursesByFilterList(idProgram, idInstitution, categoryName) {
      this.institutionId = idInstitution;
      this.programId = idProgram;
      coursesService
        .getCoursesByProgram(idProgram, idInstitution)
        .then((response) => {
          const courses = response[0].courses;
          if (courses.length) {
            this.filteredCourses = courses;
            this.category = categoryName;
          }
        });
    },
    async getCoursesByFilterListCategory(categoria_id) {
      try {
        let response = [];
        if (categoria_id) {
          response = await coursesService.getCoursesByCategory(categoria_id);
        }
        const courses = response;
        const categoryName = response[0].nameCategory;
        if (courses.length) {
          this.filteredCourses = courses;
          this.category = categoryName;
          this.nameProgram = null;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getCoursesByFilterListProgram(program_id, name) {
      try {
        let response = [];
        if (program_id) {
          response = await coursesService.returnCoursesForProgram(program_id);
        } else {
          return null;
        }
        if (response.length > 0) {
          const courses = response;
          const categoryName = name;
          if (courses.length) {
            this.filteredCourses = courses;
            this.category = categoryName;
            this.nameProgram = name;
          } else {
            return null;
          }
        } else {
          this.filteredCourses = [];
        }
      } catch (error) {
        console.error(error);
      }
    },
    async openCourseUrl() {
      try {
        const getInstitutions = await coursesService.getInstitutions();
        const institutionsList = getInstitutions.map((institution) => ({
          institutionName: institution.institucion.nombre,
          id: institution.institucion.id,
        }));
        this.institutions = institutionsList;
        this.isCardModalInstitution = true;
      } catch (error) {
        this.$buefy.toast.open({
          duration: 5000,
          message: error.response.data.message,
          position: "is-bottom",
          type: "is-danger",
        });
        console.error("error", error);
      }
    },
    openCourseRecommendedUrl(url) {
      if (url.length) {
        window.open(url, "_blank");
      }
    },
    pushButtonModal(code) {
      coursesService
        .getInstitutionsUrlMoodle(code)
        .then((url) => {
          if (url) {
            window.open(url);
          } else {
            this.$buefy.toast.open({
              duration: 5000,
              position: "is-bottom",
              message: "Se presento un error al abrir la URL",
              type: "is-danger",
            });
          }
        })
        .catch((error) => {
          this.error = error;
          console.error("Error on pushButtonModal", error);
        });
    },
    pushButtonModalCourse(code) {
      coursesService
        .getUrlCourseMoodle(code)
        .then((url) => {
          if (url) {
            window.open(url);
          } else {
            this.$buefy.toast.open({
              duration: 5000,
              position: "is-bottom",
              message: "Se presento un error al abrir la URL",
              type: "is-danger",
            });
          }
        })
        .catch((error) => {
          this.error = error;
          console.error("Error on pushButtonModal", error);
        });
    },
    redirectTo(route) {
      this.$router.push({ path: `${route}`, query: { district: "academic" } });
    },
    // TODO: Esta funcion se repite en muchos lugares
    imageCourse(image) {
      if (image) {
        if (image.substring(0, 4) === "http") {
          return image;
        }
        return process.env.VUE_APP_ARROBA + "/images/cursos/" + image;
      }
      return null;
    },
    async getAcademicData() {
      try {
        this.filteredCourses =
          await coursesService.getContinuosEducationCourses(2, 0);
        await this.getAllCoursesRecommendations();
      } catch (error) {
        console.error(error);
      }
      try {
        const getInstitutions = await coursesService.getInstitutions();
        const institutionsList = getInstitutions.map((institution) => ({
          institutionName: institution.institucion.nombre,
          id: institution.institucion.id,
        }));
        this.institutions = institutionsList;
      } catch (error) {
        console.error(error);
      }
      try {
        if (this.role === this.roleName.ADMINISTRATOR) {
          this.lastActivities = await coursesService.getLastCoursesGeneral();
        } else {
          this.lastActivities = await coursesService.getMyCourses();
          console.log("this.lastActivities ", this.lastActivities);
        }
        this.noCourses = this.lastActivities.length;
      } catch (error) {
        console.error(error);
      }
      try {
        this.recommendedCourses =
          await coursesService.getAllCoursesRecommendations("@Medellin");
        this.coursesInstitutions = await coursesService.getRecommended();
      } catch (error) {
        console.error(error);
      }
    },
    reportWindowSize() {
      if (window.innerWidth < 550) {
        this.itemsToShow = Number(1);
      } else if (window.innerWidth < 1224) {
        this.itemsToShow = Number(2);
      } else if (window.innerWidth < 1580 || window.innerWidth > 1580) {
        this.itemsToShow = Number(3);
      }
    },
    viewTutorial() {
      this.dataTutorial("academic");
    },
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
    institutionUrl() {
      return this.institutions.filter(
        (inst) =>
          inst.idInstitution === parseInt(this.institutionId) && inst.url
      );
    },
  },
  watch: {
    user: function (value) {
      this.user = value;
    },
  },
};
</script>

<style lang="scss" scoped src="./Academic.scss"></style>
