<template src='./ArrobaMedellinVerticalCardAcademic.html'></template>

<script>
export default {
  name: 'arroba-medellin-vertical-card-academic',

  props: {
    courseId: {type: [Number, String]},
    img: { type: String },
    imgDescription: { type: String, default: "Imagen" },
    title: { type: String },
    subtitle: { type: String },
    body: { type: [String, Object], required: false },
    progress: { type: [Number, String]},
    typeCard: { type: Object },
    buttonText: { type: String },
    url: { type: String, default: "" },
    classButton: { type: String, required: false },
  },
  methods: {
    pushButton() {
      this.$emit('verticalCardClickButton')
      this.$emit('openCourseModal')
    },
     imageCourse(image) {
      if (image) {
        if (image.substring(0, 4) === "http") {
          return image;
        }
        return process.env.VUE_APP_ARROBA + "/images/cursos/" + image;
      }
      return null;
    },
  },
  computed: {
    imgUrl() {
      return this.img ? this.img : 'https://storage.googleapis.com/arrobamedellin.appspot.com/Img_default.png';
    }
  }
}
</script>

<style lang="scss" scoped src="./ArrobaMedellinVerticalCardAcademic.scss"></style>
