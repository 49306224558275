<template src="./DinamicListFilter.html"> </template>

<script>
import coursesService from "@/services/Courses";

export default {
  name: "dinamic-list-filter",
  data() {
    return {
      baseTabs: [
        {
          id: "universities",
          label: "Universidades",
          content: []
        },
        {
          id: "continuing-education",
          label: "Educación Continua",
          content: [
            {
              id: "educacion-continua",
              nombre: "Educación Continua",
              categories: [],
              expanded: false
            }
          ]
        }
      ]
    };
  },
  created() {
    this.getInitData();
  },
  methods: {
    async getInitData() {
      try {
        const continueEducationCategories = [];
        const continueEducation = await coursesService.getContinuingEducationCategorys();
        continueEducation.categorias.map(category => {
          const categoryData = {
            categoria_id: category.id,
            nameCategory: category.name
          };
          continueEducationCategories.push(categoryData);
        });
        this.baseTabs[1].content[0].categories = continueEducationCategories;
        const response = await coursesService.returnProgramForInstitutions();
        const content = [];
        response.forEach(r => {
          const object = {};
          object.id = r[0].id;
          object.nombre = r[0].nombre;
          object.categories = r[1];
          object.expanded = false;
          content.push(object);
        });
        this.baseTabs[0].content = content;
      } catch (error) {
        console.error(error);
      }
    },
    expandList(tab, item) {
      const contentIndex = this.baseTabs.findIndex(
        baseTab => baseTab.id === tab
      );
      const itemIndex = this.baseTabs[contentIndex].content.findIndex(i => {
        if (item) {
          return i.id === item;
        }
      });
      this.baseTabs[contentIndex].content[itemIndex].expanded = !this.baseTabs[
        contentIndex
        ].content[itemIndex].expanded;
    },
    getFilteredCourses(categoria_id) {
      this.$emit("getCourses", categoria_id);
    },
    getFilteredCoursesProgram(programId, name) {
      this.$emit("getCoursesProgram", programId, name);
    }
  }
};
</script>

<style lang="scss" scoped src="./DinamicListFilter.scss"></style>
